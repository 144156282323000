.dashboard-benefits {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__upload {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        text-align: center;
        background-color: var(--background-color-secondary);
        border: 1px dashed var(--text-color-secondary);
        padding: 24px;
        border-radius: 6px;
        width: 70%;

        &__text {
            display: flex;
            justify-content: center;

            span {
                color: var(--primary-color);
                cursor: pointer;
            }
        }
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        justify-content: center;

        box-sizing: unset;

        &__image {
            min-width: 140px;
            max-width: 280px;
            aspect-ratio: 16/9;
            border-radius: 6px;
            position: relative;
            overflow: hidden;

            img {
                height: 100%;
                width: 100%;
            }

            &__icon {
                position: absolute;
                top: 10px;
                right: 10px;
            }
        }
    }
}
