.filter-button {
    border-radius: 50px;
    padding: 4px 14px;
    background-color: var(--primary-color);
    cursor: pointer;
    color: #000;

    &__text {
        font-size: small;
    }
}
