.no-content {
    display: flex;
    flex-direction: column;
    gap: 18px;

    &__icon {
        margin: 90px auto 0 auto;

        svg {
            scale: 5;
        }
    }

    &__text {
        text-align: center;
        font-size: 30px;
        font-weight: bold;
    }

    &__link {
        text-align: center;
        cursor: pointer;
    }
}
