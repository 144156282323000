.send-button {
    display: flex;
    flex-direction: column;

    &__button {
        display: flex;
        justify-content: center;
        margin-top: 16px;
    }
}
