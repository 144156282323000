.wait-cursor {
    text-align: center;

    &__spinner {
        animation: spin 1s linear infinite;

        z-index: 2;
        height: 20px;
        width: 20px;
        border-style: solid;
        border-width: 3px;
        border-color: #222838;
        border-radius: 50%;
        display: inline-block;
        border-top: 3px solid transparent;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
