.log-in-content {
    min-height: 100vh;
    padding: 0 16px;

    display: flex;
    flex-direction: column;
    margin-top: 80px;
    gap: 16px;

    flex: 1;

    p {
        margin: 0;
    }

    &__register {
        text-align: center;

        button {
            cursor: pointer;
            background-color: transparent;
            color: var(--primary-color);
            border: none;
            font-size: 16px;
        }
    }

    &__button {
        display: flex;
        justify-content: center;
    }
}
