.confirm-registration-header {
    &__content {
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        position: absolute;
        top: 50%;
        width: calc(100% - 32px);

        color: white;

        max-width: 680px;
        margin: 12px auto;

        gap: 12px;

        display: flex;
        flex-direction: column;

        align-items: center;

        &__title {
            margin: 0;
            text-align: center;

            font-size: 100px;
        }

        &__slogan {
            margin: 0 0 80px 0;
            text-align: center;
            font-weight: 100;
            letter-spacing: 5px;
            font-size: 35px;
        }
    }
}
