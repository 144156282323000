.social-media-input {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;

    div {
        margin: 0 !important;
    }
}
