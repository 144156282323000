.card {
    border-radius: 6px;
    padding: 12px;
    cursor: pointer;
    position: relative;

    min-width: 140px;
    max-width: 280px;
    color: white;
    background-color: var(--primary-color);

    font-family: 'Source Sans Pro', sans-serif;

    &--display {
        min-width: 280px;
    }

    &__edit {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    &__head {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &__title {
            text-align: center;
            font-weight: bold;
            font-size: 25px;
        }

        &__type {
            text-align: center;
            font-size: 14px;
            font-weight: lighter;
        }

        &__price-wrapper {
            display: flex;
            justify-content: center;
            gap: 4px;
            position: relative;

            &__price {
                font-size: 25px;
            }

            &__duration {
                font-size: 14px;
                font-weight: lighter;
                position: absolute;
            }

            &__after-duration {
                text-align: center;
                transform: translateY(-16px);
            }
        }
    }

    &__content {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__wrapper {
            display: flex;
            gap: 16px;

            &__icon {
            }

            &__text {
            }
        }
    }
}
