.header {
    position: relative;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;

        filter: brightness(50%);
    }
}
