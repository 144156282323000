.filter-buttons {
    width: 100%;

    &__headline {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;

        &__left {
            font-weight: bold;
        }

        &__right {
            cursor: pointer;
            font-size: small;
            border-bottom: grey 1px dotted;
        }
    }

    &__content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
    }
}
