.studio-image {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__image {
        aspect-ratio: 16/9;
        width: 100%;
        position: relative;
        overflow: hidden;
        border-radius: 6px;

        img {
            width: 100%;
            height: 100%;
        }

        &__actions {
            position: absolute;
            top: 10px;
            right: 10px;

            display: flex;
            gap: 12px;
        }
    }
}
