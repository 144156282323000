.sign-up-content {
    min-height: 100vh;
    padding: 0 16px;

    display: flex;
    flex-direction: column;
    margin-top: 80px;
    gap: 16px;

    flex: 1;

    p {
        margin: 0;
    }

    &__group {
        width: 100%;

        &__hint {
            font-style: italic;
            color: grey;

            &__requirements {
                &__invalid {
                    color: rgb(211, 47, 47);
                }
            }
        }
    }

    &__button {
        display: flex;
        justify-content: center;
    }
}
