.card-slider {
    display: flex;
    gap: 16px;
    width: 100%;
    overflow-x: scroll;

    &__add {
        border-radius: 6px;
        padding: 12px;
        cursor: pointer;
        position: relative;
        background-color: #d9d9d9;
        border: 1px black dashed;
        display: flex;
        justify-content: center;
        align-items: center;

        min-width: 280px;
        max-width: 280px;
        aspect-ratio: 16/9;
    }

    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-button {
        background-color: transparent;
        height: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: grey;
        border-radius: 20px;
        background-clip: padding-box;
        border: solid 3px transparent;
    }
}
