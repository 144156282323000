.address {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__row {
        display: flex;
        gap: 12px;

        &__left-input {
            width: 75%;
        }

        &__right-input {
            width: 25%;
        }
    }
}
