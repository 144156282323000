.abonnement-dialog {
    p,
    h3 {
        margin: 0;
    }
    margin-top: 12px;

    &__content {
        display: flex;
        flex-direction: column;
        gap: 12px;

        &__row {
            display: flex;
            gap: 12px;
        }
    }

    &__button {
        text-align: center;
    }
}
