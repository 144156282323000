.coming-soon {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 80px;

    h2 {
        margin: 0;
    }
}
