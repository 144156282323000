.gym-header {
    &__offer-slider {
        position: absolute;
        top: 0;
        overflow: hidden;
        width: 100%;
    }

    &__content {
        transform: translateY(-50%) translateX(-50%);
        position: absolute;
        top: 50%;
        left: 50%;
        width: calc(100% - 32px);

        color: white;

        max-width: 680px;
        margin: 12px auto;

        gap: 12px;

        display: flex;
        flex-direction: column;

        align-items: center;

        &__title {
            margin: 0;
            text-align: center;

            font-size: 100px;

            margin-bottom: 32px;
        }
    }
}
