body {
    --background-color: #121212;
    --background-color-secondary: #1E1E1E;
    --primary-color: #03DAC5;
    --secondary-color: #fff;
    --text-color: #E0E0E0;
    --text-color-secondary: #B0B0B0;
    --box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);

    background-color: var(--background-color);
    color: var(--text-color) !important;

    padding: 0;
    margin: 0;

    overflow-x: hidden;

    font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
