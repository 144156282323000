.opening-time-gym {
    display: flex;
    justify-content: space-between;

    &__day {
    }

    &__times {
    }
}
