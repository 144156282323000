.navigation-bar {
    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 64px;
        padding: 0 24px;

        &__logo {
            display: flex;
            align-items: baseline;

            h6,
            p {
                margin: 0;
            }

            h6 {
                color: var(--primary-color);
                font-size: 20px;
            }

            p {
                color: var(--text-color);
                font-size: 12px;
            }

            button {
                cursor: pointer;
                background-color: transparent;
                color: var(--text-color);
                border: none;
                font-size: 12px;
            }
        }
    }
}
